<template>
  <div class="acn-public-jobs-specialist">
    <AvailableJobsTable
      :tableColumns="tableColumns"
      :tableData="tableData"
      :tablePagination="tablePagination"
      :isLoading.sync="isLoading"
      @onPageChange="onPageChange"
      @onPerPageChange="onPerPageChange"
      @click="toSpecialistJobPage"
    ></AvailableJobsTable>
  </div>
</template>

<script>
import usePublicJobsTable from "@/modules/ACN/composables/usePublicJobsTable";

export default {
  setup(props, context) {
    const {
      isLoading,
      tableColumns,
      tableData,
      tablePagination,
      tableParams,
      updateParams,
      onPageChange,
      onPerPageChange,
      getAvailableJobs
    } = usePublicJobsTable("specialist", { context });

    return {
      isLoading,
      tableColumns,
      tableData,
      tablePagination,
      tableParams,
      updateParams,
      onPageChange,
      onPerPageChange,
      getAvailableJobs
    };
  },
  components: {
    AvailableJobsTable: () =>
      import("@/modules/ACN/components/AvailableJobsTable")
  },
  mixins: [],
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAvailableJobs();
  },
  methods: {
    toSpecialistJobPage(jobId) {
      this.$router.push({
        name: "ACNSpecialistJobDetail",
        params: { id: jobId }
      });
    }
  }
};
</script>

<style lang="scss"></style>
